/* responsive module */

(function ($, app) {
    "use strict";

    app.m69 = function () {
        //Cookie funtion. need to move
        function getCookie(name) {
            var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            if (match) return match[2].slice(-250);
            else return 'undefined';
          }

        $('.hsfrom').parsley({
          errorClass: 'is-invalid',
          successClass: 'parsley-success',
          errorsWrapper: '<span class="text-help">',
          errorTemplate: '<div></div>'
        });


        

        $(".hubspot-form").on("submit", function(e){
            e.preventDefault();
            $('.form-loaded').addClass('d-none');
            $('.form-loading').removeClass('d-none');

            // $('html, body').animate({
            //   scrollTop: $(".form-thanks").parent().closest('section').offset().top
            // }, 200);
            
            var hsFormID  = $(this).attr("data-hs");
            var Env       = $(this).attr("data-env");

            //COOKIES
            var lt_medium   = getCookie("pr_lt_utm_medium");
            var lt_source   = getCookie("pr_lt_utm_source");
            var lt_campaign = getCookie("pr_lt_utm_campaign");
            var lt_content  = getCookie("pr_lt_utm_content");
            var lt_term     = getCookie("pr_lt_utm_term");
            var ft_medium   = getCookie("pr_ft_utm_medium");
            var ft_source   = getCookie("pr_ft_utm_source");
            var ft_campaign = getCookie("pr_ft_utm_campaign");
            var ft_content  = getCookie("pr_ft_utm_content");
            var ft_term     = getCookie("pr_ft_utm_term");
            var source      = getCookie("source");
            var ad_id_kwd   = getCookie("ad_id_kwd");
            var hsutk       = getCookie("hubspotutk");
            if (hsutk == 'undefined') {
              hsutk = null;
            }


          let $fields = [
            {
              "name": "campaign_first_touch_",
              "value": ft_campaign
            },
            {
              "name": "content_first_touch_",
              "value": ft_content
            },
            {
              "name": "medium_first_touch_",
              "value": ft_medium
            },
            {
              "name": "source_first_touch_",
              "value": ft_source
            },
            {
              "name": "term_first_touch_",
              "value": ft_term
            },
            {
              "name": "campaign_last_touch_",
              "value": lt_campaign
            },
            {
              "name": "content_last_touch_",
              "value": lt_content
            },
            {
              "name": "medium_last_touch_1",
              "value": lt_medium
            },
            {
              "name": "source_last_touch_",
              "value": lt_source
            },
            {
              "name": "term_last_touch_",
              "value": lt_term
            },
            {
              "name": "lead_source_detail",
              "value": source
            },
            {
              "name": "ad_id_keyword",
              "value": ad_id_kwd
            }
          ];
          let nameOK = true;
          $('.form-input').each(function(){
            let $obj = {};
            if (typeof $(this).attr('name') != "undefined" && $(this).attr('name') == "fullname") {
              let fullname = {}
              var hs_fullName  = $(this).val().split(' ');
                  fullname['firstname'] = hs_fullName.shift();
                  fullname['lastname']  = hs_fullName.join(' ');
              if (!fullname['lastname']) {
                nameOK = false;
              }
              $.each(fullname, function(k, v) {
                $obj = {name: k, value: v}
                $fields.push($obj);
              })
            } else if (typeof $(this).attr('name') != "undefined" && $(this).attr('name') == "how_we_can_help_you__") {
              if ($(this).filter(':checked').val()) {
                $obj['name']  = $(this).attr('name');
                $obj['value'] = $(this).filter(':checked').val();
                $fields.push($obj);
              }              
            } else if (typeof $(this).attr('name') != "undefined") {
              $obj['name'] = $(this).attr('name');
              $obj['value'] = ($(this).attr('type')=='checkbox')? 'Yes' : 
                              ($(this).hasClass('input-add-country'))? $('#country_code').val()+$(this).val() : 
                              ($(this).is('textarea'))? $(this).val() : 
                              $(this).val();
              $fields.push($obj);
            }
          });
          if(!nameOK) {
            $('#fullname').parsley().removeError('forcederror', {updateClass: true})
            $('#fullname').parsley().addError('forcederror', {message: fullNameError,updateClass: true});
            $('.form-loading').addClass('d-none');
            $('.form-loaded').removeClass('d-none');
            return;
          }
            var hs_form = {
                "fields": $fields,
                "context": {
                  "hutk"    : hsutk, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                  "pageUri" : window.location.href,
                  "pageName": ( typeof pageTitle !== 'undefined') ? pageTitle : "Events"
                },
              }
            var hs_url = "https://api.hsforms.com/submissions/v3/integration/submit/7061715/"+hsFormID;
            var hs_form_data = JSON.stringify(hs_form);

            // all good lets check recaptcha
            var site_key = '6LccN7gqAAAAAPWMVRWeRcK-Ad2tJuTaMidVwUTU';
            grecaptcha.ready(function() {
              grecaptcha.execute(site_key, {action: 'submit'}).then(function(token) {
                verifyRecaptcha(token);
              });
            });

            // check recaptcha
            function verifyRecaptcha(token) {
              $.ajax({
                type: "POST",
                dataType: 'json',
                url: wpajax.ajaxurl,
                data: {
                  'action'  : 'verifyRecaptcha',
                  'token'   : token
                },
                success: function(response){
                  if(response.success) {
                    $.ajax({
                      type: "POST",
                      beforeSend: function(xhr) {
                        xhr.setRequestHeader( "Content-type", "application/json" );
                      },
                      url: hs_url,
                      data: hs_form_data,
                      dataType: "json"
                    }).done( function() {
                        if (hsFormID == 'c324b04d-ad45-4f5f-8ca8-95578e68d8fa') {
                          window.dataLayer = window.dataLayer || [];
                          window.dataLayer.push({
                            'event': 'contact-us'
                          });
                        }
                        $('.form-loading').addClass('d-none');
                        $('.form-thanks').removeClass('d-none');
                        if ($('.lp-form').length > 0) {
                          $('.lp-form').addClass('sent');
                        }
                        if(typeof redirection !== 'undefined') {
                          window.location.href = redirection;
                        }
                    })                    
                  } else {
                    $('.form-loading').addClass('d-none');
                    $('.form-error').removeClass('d-none');
                  }
                },
                error: function() {
                  $('.form-loading').addClass('d-none');
                  $('.form-error').removeClass('d-none');
                }
              });
            }

        });

        // only for lp
        if($(".lp-hero").length){

          // when loaded
          if($(window).width() < 768) {           
            $(".lp-form").appendTo(".lp-form-mobile");
          }
          // on resize
          $(window).on('resize', function(){
            var win = $(this); //this = window
            if (win.width() < 768) { 
              $(".lp-form").appendTo(".lp-form-mobile");
            } else {
              $(".lp-form").appendTo(".lp-form-desktop");
            }
        });
        }
    };
})(jQuery, app);